import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { isDesktop } from 'react-device-detect'
import { Caption } from '../../../../atoms/Caption'
import { ProgressNumbers } from '../../../../atoms/ProgressNumbers'
import { SmallTitle } from '../../../../atoms/SmallTitle'
import { StepperDots } from '../../../../atoms/StepperDots'
import { Button } from '../../../../atoms/button'
import { theme } from '../../../../theme'
import { Tooltip } from '../../../Tooltip'
import {
    Calculator,
    CalculatorConfig,
    CalculatorStepCommon as CalculatorStepCommonInterface,
    CalculatorStepGroup,
    CalculatorStepWithData,
    instanceOfCalculatorStepGroup,
} from '../../interfaces'

export interface CalculatorStepCommonProps
    extends CalculatorStepCommonInterface,
        Partial<CalculatorStepWithData> {
    icon: string
    length: number
    maxWidth?: string
    nextStep?: () => void
    noCard?: boolean
    onStepChange?: CalculatorConfig['onStepChange']
    previousStep?: () => void
    reset?: () => void
    step: number
    steps: Calculator['steps']
    type?: string
    calculatorType?: string
}
const { palette } = theme
export const CalculatorStepCommon: React.FC<CalculatorStepCommonProps> = ({
    cardCaption,
    caption = '',
    children,
    data = {},
    fieldName,
    icon,
    label,
    length,
    maxWidth,
    nextStep,
    noCard = false,
    onStepChange,
    previousStep,
    reset,
    step,
    steps,
    title,
    tooltip,
    toolCaption,
    type,
    calculatorType,
}) => {
    const desktop = isDesktop
    const hasValue = () => {
        const fieldHasValue = (fieldName?: string) =>
            fieldName ? !!data[fieldName] : true
        if (instanceOfCalculatorStepGroup(steps[step])) {
            const fieldNames = (steps[step] as CalculatorStepGroup).steps.map(
                (step) => (step as CalculatorStepWithData).fieldName
            )

            return fieldNames
                .map(fieldHasValue)
                .every((fieldName) => fieldName === true)
        }
        return fieldHasValue(fieldName)
    }
    const footnotes = [
        <>
            Your personal details will only be used to deal with your request.
            See the{' '}
            <a
                href='/privacy-notices'
                style={{
                    color: '#3681D9',
                    textDecoration: 'underline',
                }}
            >
                ILFS privacy notice
            </a>{' '}
            for your rights and how your information is used. See also the Irish
            Life Financial Service Terms of Business{' '}
            <a
                href='/sites/retail/files/Terms of Business ILFS.pdf'
                style={{
                    color: '#3681D9',
                    textDecoration: 'underline',
                }}
            >
                here
            </a>
            .
        </>,
        <>
            Before you complete your quote, please note that we may contact you
            to discuss your quote and answer any questions you may have. If you
            prefer not to be contacted please email{' '}
            <a
                style={{
                    color: '#3681D9',
                    textDecoration: 'underline',
                }}
                href='mailto: customersuccessteam@irishlife.ie'
            >
                customersuccessteam@irishlife.ie
            </a>{' '}
            to remove your email address from our contact list. Please note that
            it may take up to 24 hours to process your request.
        </>,
    ]

    const useStyles = makeStyles({
        root: {
            width: '100%',
            position: 'relative',
        },
        card: {
            backgroundColor: palette.WHITE,
            padding: desktop ? '40px 48px' : '40px 16px',
            borderRadius: '16px',
            boxShadow: '4px 0px 24px rgba(82, 97, 172, 0.08);',
            fontFamily: 'Assistant',
            position: 'relative',
            width: desktop ? 747 : undefined,
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '25px',
            width: '100%',
        },
        titles: {
            flexDirection: 'column',
        },
        section: {
            fontFamily: 'Assistant',
            fontWeight: 'normal',
            fontSize: '24px',
            lineHeight: '120%',
            color: palette.NEUTRAL_700,
            margin: '0px',
            marginBottom: '12px',
        },
        icon: {
            width: '100%',
            maxWidth: '56px',
            marginRight: '5%',
        },
        next: {
            position: 'fixed',
            right: '20px',
            bottom: '16px',
        },
        progress: {
            marginBottom: '22px',
        },
        progressNumbers: {
            position: 'absolute',
            top: '-25px',
            left: '0px',
        },
        progressNumbersDesktop: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        startButton: {
            display: 'flex',
            justifyContent: 'center',
        },
        small: {
            width: 'calc(100% - 32px)',
            minHeight: '54px',
            padding: '17px 16px',
        },
        bottomSpace: {
            paddingBottom: '100px',
        },
        tooltipCard: {
            minHeight: '0px',
        },
    })
    const maximumWidth = maxWidth ? `${maxWidth}px` : ''
    const classes = useStyles()

    return (
        <div className={classes.root} style={{ maxWidth: maximumWidth }}>
            {!desktop ? (
                <>
                    <div className={classes.progress}>
                        <div className={classes.progressNumbers}>
                            <ProgressNumbers
                                current={step + 1}
                                total={length}
                            />
                        </div>
                        {/* <StepperDots current={step + 1} total={length} /> Removed for testing as large number of stepper dots affected the mobile resolution*/}
                    </div>

                    <div className={classes.header}>
                        <img alt='icon' className={classes.icon} src={icon} />

                        <div className={classes.titles}>
                            <Caption text={toolCaption} />
                            <Text as='h2' variant='title-sm' mb='6px'>
                                {label !== undefined
                                    ? label
                                    : 'Potential cost of the cover'}
                            </Text>
                            <Caption text={caption} />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={classes.header}>
                        <Grid container item xs={6}>
                            <img
                                alt='icon'
                                className={classes.icon}
                                src={icon}
                            />
                            <div className={classes.titles}>
                                <Caption text={toolCaption} />

                                <h2 className={classes.section}>{label}</h2>
                                <Caption text={caption} />
                            </div>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={3}
                            style={{
                                justifyContent: 'flex-end',
                                alignSelf: 'flex-end',
                            }}
                        >
                            <div className={classes.progress}>
                                <div className={classes.progressNumbersDesktop}>
                                    <ProgressNumbers
                                        current={step + 1}
                                        total={length}
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={3}
                            spacing={3}
                            style={{ justifyContent: 'flex-end' }}
                        >
                            <StepperDots current={step + 1} total={length} />
                        </Grid>
                    </div>
                </>
            )}
            {noCard ? (
                children
            ) : (
                <div className={`${classes.card}`}>
                    {type === 'calculatorstepgroup' && (
                        <Caption text={cardCaption} />
                    )}
                    {type !== 'calculatorstepgroup' && (
                        <>
                            <Caption text={cardCaption} />
                            {tooltip && (
                                <Tooltip
                                    title={tooltip.title}
                                    content={tooltip.content}
                                    alignRight={true}
                                />
                            )}
                            {title && <SmallTitle text={title} />}
                        </>
                    )}
                    {children}
                    {step === 0 ? (
                        <div className={classes.startButton}>
                            <Button
                                isDisabled={!hasValue()}
                                label='Start'
                                iconPosition='right'
                                icon='rightArrow'
                                onClick={() => {
                                    window.scrollTo(0, 0)
                                    nextStep?.()
                                    onStepChange?.(step, steps, data)
                                }}
                            />
                        </div>
                    ) : undefined}
                </div>
            )}
            <Box>
                {(nextStep || previousStep) && (
                    <Box
                        justifyContent='space-between'
                        display='flex'
                        style={{ margin: '46px 0px' }}
                    >
                        {nextStep && (
                            <>
                                {reset && (
                                    <Box mr={1}>
                                        <Button
                                            label='Reset Form'
                                            onClick={reset}
                                        />
                                    </Box>
                                )}
                                {previousStep && (
                                    <Box mr={!!nextStep ? 2 : 0}>
                                        <Button
                                            label=''
                                            type='buttonIcon'
                                            iconPosition='center'
                                            icon='leftArrow'
                                            onClick={() => {
                                                window.scrollTo(0, 0)
                                                previousStep()
                                                if (onStepChange)
                                                    onStepChange(
                                                        step,
                                                        steps,
                                                        data
                                                    )
                                            }}
                                        />
                                    </Box>
                                )}

                                {step !== 0 ? (
                                    <Button
                                        isDisabled={!hasValue()}
                                        label='Next'
                                        iconPosition='right'
                                        icon='rightArrow'
                                        onClick={() => {
                                            window.scrollTo(0, 0)
                                            nextStep()
                                            if (onStepChange)
                                                onStepChange(step, steps, data)
                                        }}
                                    />
                                ) : undefined}
                            </>
                        )}
                    </Box>
                )}
            </Box>
            {type === 'calculatormobilestepgroup' && (
                <Box borderTop='1px #C4C2D0 solid'>
                    <Box pt={'16px'}>
                        {footnotes.map((footnote, index) => (
                            <Box key={index} fontSize={12} lineHeight={1.5}>
                                <Text
                                    color='monochrome.800'
                                    variant={'body-md'}
                                >
                                    <span>&#8226;</span> {footnote}
                                </Text>
                            </Box>
                        ))}
                    </Box>

                    <Text color='monochrome.800' variant={'body-md'} pt={12}>
                        For help getting a quote please call:{' '}
                        <a
                            href='tel://+35317041979'
                            style={{
                                color: '#3681D9',
                                textDecoration: 'underline',
                            }}
                        >
                            +353(0)1 704 1979
                        </a>
                    </Text>
                </Box>
            )}
        </div>
    )
}
